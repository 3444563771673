<template>
  <div
    class="flex w-full items-center bg-white rounded-md px-2 py-1 m-2 border-blue-300 border-2"
    v-for="content in contents"
    :key="content.id"
    @click="redirectToPdfPage(content)"
  >
    <div class="flex space-x-2 cursor-pointer">
      <img class="w-20 h-20 rounded-lg object-cover object-top" :src="contentImageUrl(content)" alt="">
      <div>
        <div class="text-gray-800 text-base">{{ content.title }}</div>
        <div class="text-gray-500 text-sm">{{ dateFormater(content.date) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormater } from '@/utils/time'
export default {
  name: 'Contents',
  props: ['contents'],
  methods: {
    dateFormater (date) {
      return dateFormater(date)
    },
    redirectToPdfPage ({ pdfId }) {
      this.$router.push({
        name: 'Pdf',
        query: {
          id: pdfId
        }
      })
    },
    contentImageUrl ({ imageUrl }) {
      if (imageUrl) return imageUrl
      else return require('@/assets/icons/menu/pdf-blue.svg')
    }
  }
}
</script>

<style scoped>

</style>
